import AccountService from "../services/AccountService.js";

export default {
  data() {
    return {
      accountService: new AccountService({
        auth: this.$auth,
        store: this.$store,
        http: this.$http,
      }),
    };
  },
  computed: {
    ordersData() {
      return this.accountService.account.ordersData;
    },
  },
  methods: {},
  created() {},
};
